<template>
  <div>{{ value.toFixed(2) }}</div>
</template>

<script>
export default {
  name: "ProReportFloatTdField",
  props: {
    value: null,
    round: {
      type: Number,
      default: 2,
    },
  },
};
</script>
